@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&family=Inter:wght@500&display=swap");

.logo {
  font-family: "Courier Prime", monospace;
  font-size: 40px;
}

.logo-inline {
  font-family: "Courier Prime", monospace;
  font-size: 24px;
}
